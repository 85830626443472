<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h2>Sıkça Sorulan Sorular</h2>

        <div class="formtab">
          <div :class="{ selected: tab_id == 1 }" @click="tab_id = 1">
            Vatandaşlar
          </div>
          <div :class="{ selected: tab_id == 2 }" @click="tab_id = 2">
            Hizmet Sağlayıcılar
          </div>

          <div :class="{ selected: tab_id == 3 }" @click="tab_id = 3">
            API Destek
          </div>

          <!-- <div class="search ">
            <div>
              <img src="@/assets/img/ico_search.svg" alt />
              <input type="text" placeholder="Ara" v-model="searchText" />
              <div class="searchbtn" v-if="searchText">
                <a @click="searchText = ''">
                  <img src="@/assets/img/ico_close.svg" alt
                /></a>
              </div>
            </div>
          </div> -->
        </div>

        <div class="descline">
          <div class="desctext">
            <!-- ++++++++++++++++++++++++++++++++++++ TAB 1 - Vatandaş ++++++++++++++++++++++++++++++++++++ -->

            <div v-show="tab_id == 1" class="accordion" ref="sssvat">
              <details open>
                <summary>
                  Ticari elektronik ileti izinlerimi nasıl görüntülerim?
                </summary>
                <p>
                  İYS ekranlarında Hizmet Sağlayıcılara her bir kanal (sesli
                  arama, kısa mesaj ve e-posta) için verdiğiniz tüm onayları tek
                  bir noktadan görebilir, kontrol edebilir ve reddetme hakkınızı
                  kullanabilirsiniz. Detaylı bilgi için
                  <router-link to="/vatandas-hizmetleri" style="color: #4da0df"
                    >Vatandaş Hizmetleri</router-link
                  >
                  sayfasını ziyaret edebilirsiniz.
                </p>
                <!-- <p>
                  Yönetmelik gereğince işletmelerin ticari elektronik ileti
                  izinlerini İYS’ye kaydetmesi gereken süre 31 Mayıs 2021 tarihi
                  itibarıyla son bulmuştur. 1 Haziran 2021’den itibaren İYS
                  ekranlarında Hizmet Sağlayıcılara her bir kanal (sesli arama,
                  kısa mesaj ve e-posta) için verdiğiniz tüm onayları tek bir
                  noktadan görebilir, kontrol edebilir ve reddetme hakkınızı
                  kullanabilirsiniz. Detaylı bilgi için
                  <router-link to="/vatandas-hizmetleri"
                    >Vatandaş Hizmetleri</router-link
                  >
                  sayfasını ziyaret edebilirsiniz.
                </p> -->
              </details>
              <details>
                <summary>
                  Ticari elektronik ileti aldığım bir Hizmet Sağlayıcıyı izin
                  listemde neden göremiyorum?
                </summary>
                <p>
                  İzinleriniz arasında bulunmayıp size ticari içerikli ileti
                  gönderen firmalar için şikâyet hakkınızı kullanabilirsiniz.
                  Şikâyet başvurularızı, elektronik ortamda e-Devlet kapısı
                  (Ticari Elektronik İleti Şikâyet Sistemi) veya Ticaret
                  Bakanlığı Ticari Elektronik İleti Şikâyet Sistemi
                  <a
                    href="https://tiss.ticaret.gov.tr"
                    class="blue"
                    target="_blank"
                    >https://tiss.ticaret.gov.tr</a
                  >
                  üzerinden yapabilirsiniz.
                </p>
              </details>
              <details>
                <summary>
                  Oluşturmuş olduğum şikâyet kaydını nasıl takip edebilirim?
                </summary>
                <p>
                  Ticari Elektronik İleti Şikâyet Sistemi üzerinden
                  şikâyetlerinizin mevcut durumlarını görüntüleyebilirsiniz.
                </p>
              </details>
              <details>
                <summary>Alıcı kimdir?</summary>
                <p>
                  İşletmeler yani Hizmet Sağlayıcılar tarafından gönderilen
                  ticari elektronik iletilerin muhatabı olan arama, mesaj ve
                  e-posta gibi iletişim adresi sahibi kişiler alıcı olarak
                  anılmaktadır.
                </p>
              </details>

              <details>
                <summary>Hizmet Sağlayıcı kimdir?</summary>
                <p>
                  <router-link to="/iys/kanun" class="blue"
                    ><strong>Kanun</strong></router-link
                  >
                  çerçevesinde, <b>arama</b>, <b>mesaj</b>, <b>e-posta</b> vb.
                  araçları kullanarak ticari iletişim sağlayan gerçek ve tüzel
                  kişiler Hizmet Sağlayıcı olarak adlandırılmaktadır.
                </p>
              </details>

              <details>
                <summary>İleti Yönetim Sistemi nedir?</summary>
                <p>
                  Resmî Gazete'de yayımlanarak yürürlüğe alınan Ticari İletişim
                  ve Ticari Elektronik İletiler Hakkında
                  <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                    ><strong>Yönetmelik</strong></router-link
                  >'e uygun olarak; Hizmet Sağlayıcılar'ın arama, mesaj ve
                  e-posta gibi farklı tipte ileti izinlerini saklayıp
                  yönetebilecekleri, alıcıların verdikleri izinleri görüntüleyip
                  kaldırabilecekleri, izinsiz gönderimleri şikâyet
                  edebilecekleri, kamunun ise ileti şikâyetlerini ve şikâyete
                  konu iznin durumunu görüntüleyebilecekleri, web sitesi ve
                  mobil uygulama üzerinden hizmet verecek, tüm izinleri zaman
                  damgasıyla kayıt altına alıp güvenli biçimde saklayacak ulusal
                  veri tabanı sistemidir. <br />Detaylı bilgi için
                  <router-link to="/iys/nedir">
                    <strong>Nedir?</strong>
                  </router-link>
                  sayfasını ziyaret edebilirsiniz.
                </p>
              </details>

              <details>
                <summary>Ticari elektronik ileti nedir?</summary>
                <p>
                  Gerçek ve tüzel kişilerin ticari faaliyetleri doğrultusunda
                  mal ve hizmetlerini tanıtmak, pazarlamak ya da işletmesinin
                  tanınırlığını arttırmak amacıyla elektronik iletişim
                  araçlarıyla yapılan her türlü ticari iletişimi
                  <b>ticari elektronik ileti</b> olarak adlandırılır.
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'nin getirdiği çözümler nelerdir?
                </summary>
                <p>
                  İleti Yönetim Sistemi'yle birlikte:
                  <br />• Hangi Hizmet Sağlayıcı'ya ne zaman, nerede, hangi
                  iletişim kanalı için izin verdiğinizi görüntüleyip dilerseniz
                  onayınızı kaldırabilirsiniz. Onayı kaldırdığınızda iletişim
                  adresinize ticari elektronik ileti gönderimi 3 iş günü
                  içerisinde durur.
                  <!-- <br />• Bir Hizmet Sağlayıcı için İleti Yönetim Sistemi'ne onayınız eklendiğinde bilgilendirme mesajı alırsınız. -->
                  <br />• Şikâyetler artık tek bir veri tabanında toplanır,
                  denetimi kolaylaşır, süreçler hızlanır. Sonuçlanan
                  şikâyetlerinizle ilgili bilgilendirme mesajı alırsınız.
                </p>
              </details>

              <details>
                <summary>Onay ne anlama gelmektedir?</summary>
                <p>
                  Onay, Hizmet Sağlayıcı'nın ticari elektronik ileti gönderimi
                  öncesinde alıcının rızasını almasını ifade eder. Bu rızanın
                  alınması
                  <router-link to="/iys/kanun" class="blue"
                    ><strong>Kanun</strong></router-link
                  >
                  gereğince zorunlu tutulmuştur.
                </p>
              </details>

              <details>
                <summary>
                  Ret bildirimi veya çıkış hakkı neyi ifade etmektedir?
                </summary>
                <p>
                  Alıcının, Hizmet Sağlayıcı'ya ticari elektronik ileti
                  gönderebilmesi için vermiş olduğu onayı kaldırması ret
                  bildirimi veya çıkış hakkını kullanması olarak ifade edilir.
                </p>
              </details>
              <details>
                <summary>
                  Ret bildirimi yaptığımda tarafıma gelen iletiler ne zaman
                  kesilir?
                </summary>
                <p>
                  Hizmet Sağlayıcılar, alıcının yaptığı ret bildirimini takiben
                  3 (üç) iş günü içerisinde ticari elektronik ileti göndermeyi
                  durdurur.
                </p>
              </details>

              <details>
                <summary>
                  Ret bildirimi yapmamın üzerinden 3 iş günü geçti. İleti almaya
                  devam ediyorum. Ne yapabilirim?
                </summary>
                <p>
                  İlgili Hizmet Sağlayıcı hakkında e-Devlet kapısında yer alan
                  Ticari Elektronik İleti Şikâyet Sistemi üzerinden veya TISS
                  <a
                    href="https://tiss.ticaret.gov.tr"
                    class="blue"
                    target="_blank"
                    >Vatandaş Portalı</a
                  >
                  üzerinden şikâyet kaydı oluşturabilirsiniz.
                </p>
              </details>

              <!-- <details>
                <summary>
                  İleti Yönetim Sistemi'ni ne zaman kullanmaya başlayabilirim?
                </summary>
                <p>
                  İleti Yönetim Sistemi, 1 Ocak 2021 tarihi itibarıyla
                  vatandaşlarımızın kullanımına açılmıştır. Artık hangi Hizmet
                  Sağlayıcı'ya, hangi tarihte, nereden onay verdiğinizi
                  görüntüleyebilecek, dilerseniz Bakanlık'ın vatandaşlar için
                  tanımış olduğu geçiş süreci boyunca çıkış hakkınızı
                  kullanabileceksiniz. 150 bin adet ve altında ticari elektronik
                  ileti onayı (telefon numarası ve eposta adresi sayısı toplamı)
                  olan hizmet sağlayıcılara ait izinler için bu tarih 15 Temmuz
                  2021'dir.
                </p>
              </details> -->

              <!-- <details>
                <summary>Geçiş süreci ne anlama gelmektedir?</summary>
                <p>
                  Geçiş süreci, Hizmet Sağlayıcılar ve vatandaşlar için farklı
                  zaman aralıklarını ifade etmektedir. Hizmet Sağlayıcılar, 1
                  Ocak 2021'ye kadar mevcut izinlerini İleti Yönetim Sistemi'ne
                  yükleyecektir. Bu tarihe kadar yüklenmeyen izinler geçersiz
                  kabul edilecektir. 150 bin adet ve altında ticari elektronik
                  ileti onayı (telefon numarası ve eposta adresi sayısı toplamı)
                  olan hizmet sağlayıcılar için bu tarih 1 Haziran 2021'dir.
                  <br />Vatandaşlar ise, 1 Ocak 2021 ile 15 Şubat 2021 arasında
                  Hizmet Sağlayıcılar'ın sisteme yüklemiş oldukları izinleri
                  yöneteceklerdir. 15 Şubat 2021 tarihi itibarıyla çıkış hakkı
                  kullanılmamış izinler onay verilmiş olarak kabul edilecektir.
                  150 bin adet ve altında ticari elektronik ileti onayı (telefon
                  numarası ve eposta adresi sayısı toplamı) olan hizmet
                  sağlayıcılara ait izinler için bu tarih 15 Temmuz 2021'dir.
                </p>
              </details> -->

              <!-- <details>
                <summary>
                  Çıkış hakkımı ne zamana kadar kullanmam gerekir?
                </summary>
                <p>
                  15 Şubat 2021 tarihine kadar çıkış hakkınızı kullanmanız
                  gerekir. Bu tarih itibarıyla ret bildirimi yapılmamış Hizmet
                  Sağlayıcılar, Bakanlık tarafından tanınan geçiş süreci
                  sonrasında onaylı olarak kabul edilecektir. Ancak bu tarihten
                  sonra da dilediğiniz zaman ret hakkınızı kullanabilirsiniz.
                  150 bin adet ve altında ticari elektronik ileti onayı (telefon
                  numarası ve eposta adresi sayısı toplamı) olan hizmet
                  sağlayıcılara ait izinler için bu tarih 15 Temmuz 2021'dir.
                </p>
              </details> -->

              <details>
                <summary>
                  İleti Yönetim Sistemi içerisinde yapacağım işlemler ücretli
                  midir?
                </summary>
                <p>
                  Hayır. İleti Yönetim Sistemi, tüm vatandaşlarımıza her
                  kanaldan
                  <b>ücretsiz</b> hizmet verecektir. Detaylı bilgi için
                  <router-link to="/vatandas-hizmetleri" style="color: #4da0df"
                    >Vatandaş Hizmetleri</router-link
                  >
                  sayfasını ziyaret edebilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'ni nasıl kullanabilirim?
                </summary>
                <p>
                  İleti Yönetim Sistemi'ni
                  <a
                    href="https://vatandas.iys.org.tr"
                    class="blue"
                    target="_blank"
                    >İleti Yönetim Sistemi Web</a
                  >
                  veya
                  <a
                    href="https://www.turkiye.gov.tr/ticari-elektronik-ileti-yonetim-sistemi"
                    class="blue"
                    target="_blank"
                    >e-Devlet kapısı</a
                  >
                  üzerinden kullanabilirsiniz.
                </p>
              </details>

              <details>
                <summary>İleti Yönetim Sistemi Web nedir?</summary>
                <p>
                  <a
                    href="https://vatandas.iys.org.tr"
                    class="blue"
                    target="_blank"
                    >https://vatandas.iys.org.tr</a
                  >
                  üzerinden ticari elektronik iletilere ilişkin izin ve şikâyet
                  süreçlerinin yönetilmesine imkân tanıyan web platformudur.
                  Detaylı bilgi için
                  <router-link to="/vatandas-hizmetleri"
                    >Vatandaş Hizmetleri</router-link
                  >
                  sayfasını ziyaret edebilirsiniz.
                </p>
              </details>

              <!-- <details>
                <summary>İleti Yönetim Sistemi Mobil nedir?</summary>
                <p>Mobil uygulama üzerinden ticari elektronik iletilere ilişkin izin ve şikâyet süreçlerinin yönetilmesine imkân tanıyan platformdur.</p>
              </details>

              <details>
                <summary>İleti Yönetim Sistemi Çağrı Merkezi nedir?</summary>
                <p>
                  Türkiye'nin her yerinden tamamen ücretsiz olarak arayarak:
                  <br />• Hizmet Sağlayıcılar'a onay ve ret bildirimi yapabilirsiniz.
                  <br />• Ticari elektronik iletilere ilişkin şikâyet bildirimi yapıp şikâyet sorgulama işlemlerini gerçekleştirebilirsiniz.
                </p>
              </details>-->

              <details>
                <summary>
                  İleti Yönetim Sistemi'nde profil oluşturmanın benim için
                  sağlayacağı faydalar nelerdir?
                </summary>
                <p>
                  Tek bir kullanıcı profiliyle birden fazla iletişim adresiniz
                  için onay, ret ve şikâyet süreçlerini yönetebilirsiniz.
                  Detaylı bilgi için
                  <router-link to="/vatandas-hizmetleri" style="color: #4da0df"
                    >Vatandaş Hizmetleri</router-link
                  >
                  sayfasını ziyaret edebilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'nde profilim yok ancak tarafıma reklam,
                  tanıtım, pazarlama mesajı gelmesini istemiyorum. Ret bildirimi
                  yapabilir miyim?
                </summary>
                <p>
                  Evet. İlgili iletişim adresinize (telefon numarası veya
                  e-posta adresi) gelecek doğrulama koduyla giriş yaparak tüm
                  işletme ve markalar için iletişim tercihlerinizi
                  yönetebilirsiniz. Detaylı bilgi için
                  <router-link to="/vatandas-hizmetleri" style="color: #4da0df"
                    >Vatandaş Hizmetleri</router-link
                  >
                  sayfasını ziyaret edebilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  Bankamı izin listemden çıkardım. Ödeme ve üyelik girişi gibi
                  işlemlerde tarafıma gönderilen tek seferlik doğrulama kodu
                  gelmeye devam edecek mi?
                </summary>
                <p>
                  Evet, ret işleminiz yalnızca firmalara ait kampanya, reklam,
                  tanıtım ve pazarlama iletileri için geçerlidir. Bilgilendirme
                  dâhilindeki tüm hizmetlerden yararlanmaya devam edebilirsiniz.
                </p>
              </details>

              <details>
                <summary>Kişisel verilerim korunuyor mu?</summary>
                <p>
                  Evet. İleti Yönetim Sistemi'ne yüklediğiniz veriler güvenlik
                  standartları dâhilinde ulusal veri tabanlarında
                  saklanmaktadır.
                </p>
              </details>
            </div>

            <!-- ++++++++++++++++++++++++++++++++++++ TAB 2 - > Hizmet Sağlayıcılar ++++++++++++++++++++++++++++++++++++ -->

            <div v-show="tab_id == 2" class="accordion" ref="ssshs">
              <details open>
                <summary>İleti Yönetim Sistemi nedir?</summary>
                <p>
                  Resmî Gazete'de yayımlanarak yürürlüğe alınan Ticari İletişim
                  ve Ticari Elektronik İletiler Hakkında
                  <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                    ><strong>Yönetmelik</strong></router-link
                  >'e uygun olarak; Hizmet Sağlayıcılar'ın arama, mesaj ve
                  e-posta gibi farklı tipte ileti izinlerini saklayıp
                  yönetebilecekleri, alıcıların verdikleri izinleri görüntüleyip
                  kaldırabilecekleri, izinsiz gönderimleri şikâyet
                  edebilecekleri, kamunun ise ileti şikâyetlerini ve şikâyete
                  konu iznin durumunu görüntüleyebilecekleri, web sitesi ve
                  mobil uygulama üzerinden hizmet verecek, tüm izinleri zaman
                  damgasıyla kayıt altına alıp güvenli biçimde saklayacak ulusal
                  veri tabanı sistemidir. <br />Detaylı bilgi için
                  <router-link to="/iys/nedir">
                    <strong>Nedir?</strong>
                  </router-link>
                  sayfasını ziyaret edebilirsiniz.
                </p>
              </details>

              <details>
                <summary>Ticari elektronik ileti nedir?</summary>
                <p>
                  Gerçek ve tüzel kişilerin ticari faaliyetleri doğrultusunda
                  mal ve hizmetlerini tanıtmak, pazarlamak ya da işletmesinin
                  tanınırlığını arttırmak amacıyla elektronik iletişim
                  araçlarıyla yapılan her türlü ticari iletişimi
                  <b>ticari elektronik ileti</b> olarak adlandırılır.
                </p>
              </details>

              <details>
                <summary>Hizmet Sağlayıcı kimdir?</summary>
                <p>
                  <router-link to="/iys/kanun" class="blue"
                    ><strong>Kanun</strong></router-link
                  >
                  çerçevesinde, <b>arama</b>, <b>mesaj</b>, <b>e-posta</b> vb.
                  araçları kullanarak ticari iletişim sağlayan gerçek ve tüzel
                  kişiler Hizmet Sağlayıcı olarak adlandırılmaktadır.
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'ne nasıl başvurabilirim?
                </summary>
                <p>
                  Sistemi kullanmak için İleti Yönetim Sistemi başvuru formunu
                  kullanarak gerekli bilgi ve belgeleri iletmeniz gerekmektedir.
                  Başvuru aşamasında girmiş olduğunuz bilgi ve belgeler kontrol
                  edilip doğrulandıktan sonra hesabınız kullanıma açılır.<br />
                  Detaylı bilgi için
                  <router-link
                    to="/hizmet-saglayici/basvuru/nasil-yapilir"
                    class="blue"
                    ><strong>Başvuru Nasıl Yapılır?</strong></router-link
                  >
                  sayfamızı ziyaret edebilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'ne başvuru aşamasında hangi bilgi ve
                  belgeler gereklidir?
                </summary>
                <p>
                  <b>• Hizmet Sağlayıcı'nın</b> MERSİS numarası ve ticari
                  elektronik ileti gönderimi yaptığı markaların tescil belgeleri
                  <br />
                  <b>• İmza yetkilisinin</b> T.C. kimlik numarası, cep telefonu
                  numarası, kurumsal e-posta adresi.
                </p>
              </details>

              <!-- <details>
                <summary>
                  Hesabım açıldıktan sonra elimdeki mevcut izinleri ne zamana
                  kadar İleti Yönetim Sistemi'ne yüklemeliyim?
                </summary>
                <p>
                  150 bin adedin üstünde ticari elektronik ileti onayı (telefon
                  numarası ve eposta adresi sayısı toplamı) olan hizmet
                  sağlayıcıların 31 Aralık 2020'ye kadar, 150 bin adet ve
                  altında ticari elektronik ileti onayı (telefon numarası ve
                  eposta adresi sayısı toplamı) olan hizmet sağlayıcıların ise
                  31 Mayıs 2021'e kadar tüm izinleri sisteme aktarmış olması
                  gerekmektedir. Mevzuat gereğince, bu tarih itibarıyla İYS'ye
                  aktarılmamış izinler geçersiz kabul edilecektir.
                </p>
              </details> -->

              <details>
                <summary>
                  İzinlerimi İleti Yönetim Sistemi'ne nasıl ekleyeceğim?
                </summary>
                <p>
                  İzinlerizi sisteme iys.org.tr üzerinden tek tek veya .csv
                  dosyalarıyla toplu halde; İYS API üzerinden ise teknik
                  entegrasyon yoluyla ekleyebilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'ne ekleyeceğim izinler hangi verileri
                  içerecektir?
                </summary>
                <p>
                  Yükleyeceğiniz izinler sadece şu verileri içerecektir:
                  <br />• İletişim adresi (Telefon numarası veya e-posta adresi)
                  <br />• İzin tarihi <br />• İletişim kanalı
                  (arama/mesaj/e-posta) <br />• Alıcı tipi (Bireysel/Tacir)
                  <br />• İzin kaynağı ('1 Mayıs 2015 öncesi', 'ıslak imzalı
                  onay formu', 'web sitesi' vb.)
                </p>
              </details>

              <details>
                <summary>Tacir alıcı ne anlama gelmektedir?</summary>
                <p>
                  Tacir, Mevzuat’ta tacir veya esnaf olarak nitelenen alıcıdır.
                  Bir iletişim adresi hem bireysel hem tacir niteliğiyle İYS’ye
                  kaydedilebilir.
                </p>
              </details>

              <details>
                <summary>
                  Başka bir tacire veya esnafa ticari elektronik ileti
                  gönderirken onay almam gerekir mi?
                </summary>
                <p>
                  <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                    ><strong>Yönetmelik</strong></router-link
                  >
                  kapsamında tacir veya esnaf olan alıcıların elektronik
                  iletişim adreslerine gönderilen ticari elektronik iletiler
                  için önceden onay alınması zorunlu değildir. Ancak tacir ve
                  esnafların reddetme hakkını kullanması halinde onayları
                  alınmadan ticari elektronik ileti gönderilemez.
                </p>
              </details>

              <details>
                <summary>
                  Mevzuat gereği tacirler veya esnaflardan önceden onay alınması
                  zorunlu değil. Yine de bu alıcıları İYS’ye bildirmeli miyim?
                </summary>
                <p>
                  Evet, bildirmelisiniz. Tacir veya esnaflardan önceden onay
                  alınması gerekmez ancak
                  <i
                    >İYS üzerinde onayı bulunmayan alıcılara ticari elektronik
                    ileti gönderilemez</i
                  >
                  hükmü sebebiyle ticari elektronik ileti gönderiminden önce
                  tacir veya esnaf olan alıcıların elektronik iletişim
                  adreslerinin İYS’ye kaydedilmesi gerekmektedir.
                </p>
              </details>

              <!-- <details>
                <summary>
                  Elimdeki ret kayıtlarını da 31 Aralık’a kadar İYS’ye aktarmam
                  gerekiyor mu?
                </summary>
                <p>
                  Hayır. Sadece onaylı elektronik iletişim adreslerini sisteme
                  yüklemelisiniz. İleti Yönetim Sistemi'nde kaydı bulunmayan
                  elektronik iletişim adresi aynı zamanda ret anlamı
                  taşımaktadır.<br />
                  Ancak 31 Aralık’dan sonra 150 bin adedin üstünde ticari
                  elektronik ileti onayı (telefon numarası ve eposta adresi
                  sayısı toplamı) olan hizmet sağlayıcılar, kendisine iletilen
                  ret bildirimlerini üç iş günü içinde İYS’ye bildirmekle
                  yükümlüdür. 150 bin adet ve altında ticari elektronik ileti
                  onayı (telefon numarası ve eposta adresi sayısı toplamı) olan
                  hizmet sağlayıcılar için bu tarih 31 Mayıs 2021'dir.
                </p>
              </details> -->

              <details>
                <summary>
                  İleti Yönetim Sistemi'yle birlikte Hizmet Sağlayıcıların
                  gönderdiği ticari elektronik iletilerde çıkış kanalı sunma
                  zorunluluğu kalktı mı?
                </summary>
                <p>
                  Hayır. Mevzuat gereğince, ticari elektronik ileti hangi
                  iletişim kanalıyla gönderildiyse ret bildirimi de kolay ve
                  bedelsiz bir şekilde olmak üzere aynı iletişim kanalıyla
                  sağlanır.
                </p>
              </details>

              <details>
                <summary>
                  Kişisel Verilerin Korunması Kanunu kapsamında alıcılardan
                  almış olduğum onayları da İleti Yönetim Sistemi'ne yüklemem
                  gerekiyor mu?
                </summary>
                <p>
                  Hayır. İleti Yönetim Sistemi, 6563 sayılı Elektronik Ticaretin
                  Düzenlenmesi Hakkındaki Kanun kapsamında alınması gereken
                  ticari elektronik ileti izinlerinin tutulduğu veri tabanıdır.
                </p>
              </details>

              <!-- <details>
                <summary>
                  1 Ocak 2021 öncesinde elimdeki tüm elektronik iletişim
                  adreslerini “onaylı” olarak sisteme yükleyebilir miyim?
                </summary>
                <p>
                  Mevzuat gereğince sadece onaylı elektronik iletişim
                  adreslerinin sisteme eklenmesi gerekmektedir. 1 Ocak 2021
                  tarihine kadar yüklenmiş onaylar için vatandaşlar, 15 Şubat
                  2021'e kadar izinlerini kontrol edebilecektir. 150 bin adet ve
                  altında ticari elektronik ileti onayı (telefon numarası ve
                  eposta adresi sayısı toplamı) olan hizmet sağlayıcıların 31
                  Mayıs 2021'e kadar yükledikleri izinlerin son kontrol tarihi
                  15 Temmuz 2021'dir. Bu tarihlerden itibaren söz konusu bu
                  onaylar geçerli sayılacaktır.
                </p>
              </details> -->

              <!-- <details>
                <summary>
                  1 Ocak 2021 öncesinde almış olduğum onayları belgeleyen imzalı
                  formları sisteme yüklemem gerekiyor mu?
                </summary>
                <p>
                  Hayır. Hizmet sağlayıcılar bu onayları sisteme “beyan” usulü
                  yükleyeceklerdir.
                </p>
              </details> -->

              <details>
                <summary>
                  İleti Yönetim Sistemi’nde kayıtlı olmayan e-posta adreslerine
                  de gönderim yapabilir miyim?
                </summary>
                <p>
                  Hayır. Gönderilecek e-postalar için de alınmış onayların İleti
                  Yönetim Sistemi’ne eklenmesi gerekmektedir. İYS üzerinde onayı
                  bulunmayan alıcılara ticari elektronik ileti gönderilemez.
                </p>
              </details>

              <details>
                <summary>
                  E-posta gönderimleri öncesinde aracı Hizmet Sağlayıcılar
                  tarafından izin sorgulaması yapılması zorunlu mu?
                </summary>
                <p>
                  Hayır.
                  <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                    ><strong>Yönetmelik</strong></router-link
                  >
                  kapsamında e-posta kanalı için aracı Hizmet Sağlayıcıların
                  sorgulama yükümlülüğü bulunmamaktadır. Ancak diğer kanallarda
                  olduğu gibi Hizmet Sağlayıcı e-posta kanalında da sadece
                  onaylı alıcılara ticari elektronik ileti gönderebilir.
                  <br />Katma değerli hizmetlerden faydalanan Hizmet
                  Sağlayıcılar dilerse doğrudan veya iş ortağı üzerinden
                  entegrasyon yoluyla e-posta gönderimleri öncesinde alıcıların
                  izin durumunu İYS’den sorgulayabilir.
                </p>
              </details>

              <details>
                <summary>
                  Alıcıların
                  <a
                    href="https://hs.iys.org.tr"
                    class="blue"
                    target="_blank"
                    style="text-decoration: underline"
                    >İYS Web</a
                  >,
                  <a
                    href="https://www.turkiye.gov.tr/ticari-elektronik-ileti-yonetim-sistemi"
                    class="blue"
                    target="_blank"
                    style="text-decoration: underline"
                    >e-Devlet kapısı</a
                  >
                  vb. kanallar üzerinden yapacağı izin durumu değişikliklerinden
                  nasıl haberdar olurum?
                </summary>
                <p>
                  Tüm Hizmet Sağlayıcılar, günlük izin değişikliği raporunu
                  <a
                    href="https://hs.iys.org.tr"
                    class="blue"
                    target="_blank"
                    >İYS Web</a
                  >
                  üzerinden indirebilirler. <br />
                  API erişimi olan hizmet sağlayıcılar alıcıların yapmış olduğu
                  onay durumu değişikliklerinden İYS API’yi kullanarak
                  (pull/push servisleri) haberdar olurlar.
                </p>
                <!-- <p>
                  Tüm Hizmet Sağlayıcılar, günlük izin değişikliği raporunu İYS
                  Web üzerinden indirebilirler. <br />Katma değerli hizmetlerden
                  faydalanan Hizmet Sağlayıcılar ise alıcıların yapmış olduğu
                  onay durumu değişikliklerinden İYS API’yi kullanarak
                  (pull/push servisleri) haberdar olurlar.
                </p> -->
              </details>

              <details>
                <summary>
                  Bir alıcının telefon hattı kapanırsa nasıl haberdar olurum?
                </summary>
                <p>
                  Mevzuat kapsamında aracı Hizmet Sağlayıcılar, alıcıların
                  abonelik hattını kapatmaları halinde İYS’ye bu durumu
                  bildirir. Tüm Hizmet Sağlayıcılar, günlük izin değişikliği
                  raporunu İYS Web üzerinden indirebilirler. API erişimi olan
                  hizmet sağlayıcılar alıcıların yapmış olduğu onay durumu
                  değişikliklerinden İYS API’yi kullanarak (pull/push
                  servisleri) haberdar olurlar.
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'ne kaydettiğim izinler ne kadar süreyle
                  saklanacak?
                </summary>
                <p>
                  İleti Yönetim Sistemi'nde tutulan kayıtlar süresiz olarak
                  saklanır.
                </p>
              </details>

              <!-- <details>
                <summary>
                  1 Ocak 2021'e kadar yüklemiş olduğum geçmiş tarihli izinlerin
                  ispat yükümlülüğü İYS'de mi olacak?
                </summary>
                <p>
                  Hayır. İleti Yönetim Sistemi'ne yüklenen geçmiş tarihli tüm
                  izinlerin ispat yükümlülüğü hizmet sağlayıcıdadır. Ancak 1
                  Ocak 2021'den itibaren ispat yükümlülüğü İYS'de olacak şekilde
                  onay almaya başlayabilirsiniz. 150 bin adet ve altında ticari
                  elektronik ileti onayı (telefon numarası ve eposta adresi
                  sayısı toplamı) olan hizmet sağlayıcılar için bu tarih 1
                  Haziran 2021'dir.
                </p>
              </details> -->

              <!-- <details>
                <summary>
                  1 Ocak 2021'e kadar elimdeki onayları sisteme yüklemezsem ne
                  olur?
                </summary>
                <p>
                  <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                    ><strong>Yönetmelik</strong></router-link
                  >
                  gereğince 1 Ocak 2021'e kadar İleti Yönetim Sistemi'ne
                  aktarılmayan onaylar geçersiz olacaktır. 150 bin adet ve
                  altında ticari elektronik ileti onayı (telefon numarası ve
                  eposta adresi sayısı toplamı) olan hizmet sağlayıcılar için bu
                  tarih 1 Haziran 2021'dir.
                </p>
              </details> -->

              <details>
                <summary>
                  İleti Yönetim Sistemi'ne kayıt olmadan ticari elektronik ileti
                  göndermeye devam edebilir miyim?
                </summary>
                <p>
                  Hayır.
                  <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                    ><strong>Yönetmelik</strong></router-link
                  >
                  gereğince ticari elektronik ileti göndermek isteyen Hizmet
                  Sağlayıcıların İleti Yönetim Sistemi’ne kayıt olması
                  zorunludur.
                </p>
              </details>

              <details>
                <summary>
                  Türkiye'de yerleşik olmayan ancak yurt içi müşterilerine
                  ticari elektronik gönderimi yapan firmalar İYS'ye kaydolmak
                  zorunda mıdır?
                </summary>
                <p>
                  T.C. Ticaret Bakanlığımıza sorduğumuz bu soruya ilişkin
                  tarafımıza iletilen açıklama şu şekildedir: <br />"Türkiye'de
                  yerleşik olsun ya da olmasın, ticari elektronik ileti
                  göndermek isteyen gerçek ya da tüzel kişilerin anılan mevzuat
                  hükümlerine uygun davranması ve Ticari Elektronik İleti
                  Yönetim Sistemi'ne (İYS) kaydolması gerektiği
                  değerlendirilmektedir." <br />Bu itibarla Türkiye'de yerleşik
                  olmayan ancak Türkiye'deki alıcılara ticari elektronik ileti
                  gönderen firmaların apostil tasdiki yapılmış faaliyet
                  belgeleriyle başvurudaki yetkililerin yetki belgelerini
                  bilgi@iys.org.tr'ye göndermeleri gerekmektedir. Aynı belgeler
                  daha sonra posta yoluyla da İleti Yönetim Sistemi A.Ş. Merkez
                  adresine iletilmelidir.
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'ne onaylı iletişim adreslerini
                  aktarmadan ticari elektronik ileti göndermeye devam edebilir
                  miyim?
                </summary>
                <p>
                  Hayır.
                  <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                    ><strong>Yönetmelik</strong></router-link
                  >
                  gereğince, İleti Yönetim Sistemi'nde onayı bulunmayan
                  alıcılara ticari elektronik ileti gönderilemez.
                </p>
              </details>

              <details>
                <summary>Onay ne anlama gelmektedir?</summary>
                <p>
                  Onay, Hizmet Sağlayıcı'nın ticari elektronik ileti gönderimi
                  öncesinde alıcının rızasını almasını ifade eder. Bu rızanın
                  alınması
                  <router-link to="/iys/kanun" class="blue"
                    ><strong>Kanun</strong></router-link
                  >
                  gereğince zorunlu tutulmuştur.
                </p>
              </details>

              <details>
                <summary>
                  Ticari elektronik ileti gönderim onayını nasıl alabilirim?
                </summary>
                <p>
                  Ticari elektronik ileti gönderim onayı üç farklı şekilde
                  alınabilir:
                  <br />• Fiziksel ortamda imzayla <br />• Elektronik ortamda
                  <br />• İleti Yönetim Sistemi yoluyla
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi aracılığıyla nasıl onay alabilirim?
                </summary>
                <p>
                  Dilerseniz, İleti Yönetim Sistemi'nin katma değerli hizmetler
                  kapsamında sunacağı, kısa mesaj numarası ve kısa link
                  aracılığıyla onay alma servisinden faydalanabilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  Ret bildirimi veya çıkış hakkı neyi ifade etmektedir?
                </summary>
                <p>
                  Alıcının, Hizmet Sağlayıcı'ya ticari elektronik ileti
                  gönderebilmesi için vermiş olduğu onayı kaldırması ret
                  bildirimi veya çıkış hakkını kullanması olarak ifade edilir.
                </p>
              </details>

              <details>
                <summary>
                  Ret/çıkış hakkını alıcılara nasıl sunabilirim?
                </summary>
                <p>
                  Hizmet Sağlayıcı, alıcının ret bildiriminde bulunabilmesi için
                  kendisi tarafından sağlanan ya da İleti Yönetim Sistemi
                  tarafından sunulan müşteri hizmetleri numarası, kısa mesaj
                  numarası veya yalnızca ret bildirimine özgülenmiş bir URL
                  adresi gibi erişilebilir iletişim adresine ticari elektronik
                  iletide yer verir.
                  <br />Ticari elektronik ileti hangi iletişim kanalıyla
                  gönderildiyse ret bildirimi de kolay ve ücretsiz bir şekilde
                  olmak üzere aynı iletişim kanalıyla sağlanır. Ret bildirim
                  imkânı, gönderilen her ticari elektronik iletide yer alır.
                </p>
              </details>

              <details>
                <summary>
                  Ticari elektronik ileti içerisinde alıcılara İleti Yönetim
                  Sistemi üzerinden nasıl çıkış hakkı sunabilirim?
                </summary>
                <p>
                  Dilerseniz, İleti Yönetim Sistemi'nin katma değerli hizmetler
                  kapsamında sunacağı kısa mesaj numarası ve kısa link
                  aracılığıyla alıcılara çıkış hakkı sunabilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  Müşterilerimin onay ve ret bildirimlerini İleti Yönetim
                  Sistemi dışında alıyorum. Ne yapmalıyım?
                </summary>
                <p>
                  <router-link to="/iys/yonetmelik-degisiklik" class="blue"
                    ><strong>Yönetmelik</strong></router-link
                  >
                  gereğince İleti Yönetim Sistemi haricinde almış olduğunuz onay
                  ve ret bildirimlerini 3 (üç) iş günü içerisinde sisteme
                  eklemeniz gerekmektedir.
                </p>
              </details>

              <details>
                <summary>
                  Alıcının reddetme hakkını İYS üzerinden kullandığı durumda
                  Hizmet Sağlayıcı ne kadar sürede haberdar olmalıdır?
                </summary>
                <p>
                  T.C. Ticaret Bakanlığımıza sorduğumuz bu soruya ilişkin
                  tarafımıza iletilen açıklama şu şekildedir: <br />"Alıcının
                  ret hakkını İYS üzerinden kullanması halinde, İYS tarafından
                  ileti trafiğinin durdurulması ve ret hakkının kullanıldığına
                  dair bilginin Hizmet Sağlayıcıyla entegrasyona bağlı olarak
                  anlık veya azami üç iş günü içinde Hizmet Sağlayıcıya
                  bildirilmesi gerekmektedir."
                </p>
              </details>

              <details>
                <summary>
                  İleti Yönetim Sistemi'ne yüklediğim izinler hangi sunucularda
                  saklanıyor?
                </summary>
                <p>
                  İleti Yönetim Sistemi'ne iletilen tüm veriler yurt içindeki
                  güvenlikli veri merkezlerimizde depolanır ve hiçbir şekilde
                  yurt dışına çıkmaz.
                </p>
              </details>

              <details>
                <summary>
                  Göndereceğim her ileti için onay almam gerekir mi?
                </summary>
                <p>
                  Hayır.
                  <router-link to="/iys/kanun" class="blue"
                    ><strong>Kanun</strong></router-link
                  >
                  gereğince devam eden abonelik, üyelik veya ortaklık durumu ile
                  tahsilat, borç hatırlatma, bilgi güncelleme, satın alma ve
                  teslimat veya benzeri durumlara ilişkin bildirimleri içeren
                  iletiler ile Hizmet Sağlayıcıya ilgili mevzuatla getirilen
                  bilgi verme yükümlülüğü durumlarında önceden onay alma
                  zorunluluğu aranmaz.
                </p>
              </details>

              <details>
                <summary>
                  Müşterilerimle hizmet (kargo takip, abonelik, borç durum vb.)
                  bilgisi paylaşıyorum. Onay almam gerekir mi?
                </summary>
                <p>
                  Hayır.
                  <router-link to="/iys/kanun" class="blue"
                    ><strong>Kanun</strong></router-link
                  >
                  gereğince devam eden abonelik, üyelik veya ortaklık durumu ile
                  tahsilat, borç hatırlatma, bilgi güncelleme, satın alma ve
                  teslimat veya benzeri durumlara ilişkin bildirimleri içeren
                  iletiler ile Hizmet Sağlayıcıya ilgili mevzuatla getirilen
                  bilgi verme yükümlülüğü durumlarında önceden onay alma
                  zorunluluğu aranmaz.
                </p>
              </details>

              <details>
                <summary>
                  Müşterilerime kutlama ve tebrik mesajı gönderiyorum. Onay
                  almam gerekir mi?
                </summary>
                <p>
                  Evet. Kutlama, tebrik veya temenni gibi işletmenizin
                  tanınırlığını arttırmaya yönelik ticari elektronik iletiler
                  öncesinde onay almanız gerekir.
                </p>
              </details>

              <details>
                <summary>
                  Müşterilerime sadece bilgilendirme mesajı gönderiyorum ancak
                  devamında memnuniyet anketi yapmak için arama yapıyorum.
                  Müşterilerilerimden onay almam gerekiyor mu?
                </summary>
                <p>
                  <b
                    >T.C. Ticaret Bakanlığımızın bu soruya ilişkin görüşü şu
                    şekildedir:</b
                  >
                  <br />
                  Verilen hizmetle ilgili müşteri memnuniyetini ölçmek amacıyla
                  herhangi bir ürün veya hizmet özendirilmemek ve makul bir süre
                  içinde gerçekleştirilmek kaydıyla satın alınan mal ve hizmet
                  sonrasında müşterilere sadece bir defaya mahsus olmak üzere
                  memnuniyet anketi gönderilebileceği değerlendirilmektedir.”
                  <br />
                  Bu kapsamda bu şekilde bir kereye mahsus yapılacak anket
                  aramalarında İYS üzerinden sorgulama yapılmasına gerek yoktur.
                </p>
              </details>

              <details>
                <summary>
                  Aracı Hizmet Sağlayıcılar ve İleti Yönetim Sistemi
                  bilgilendirme ve ticari ileti ayrımını nasıl yapıyor?
                </summary>
                <p>
                  Bir elektronik iletinin ticari ileti mi yoksa bilgilendirme
                  iletisi mi olduğunun ayrımını yapmak Hizmet Sağlayıcının
                  sorumluluğundadır. Hizmet Sağlayıcı, bu sorumluluk kapsamında
                  ileti gönderim hizmeti aldığı ilgili aracı Hizmet Sağlayıcıya
                  bildirimde bulunur.
                </p>
              </details>

              <details>
                <summary>
                  Alıcılara yalnızca mobil uygulama üzerinden anlık bildirim
                  (push notification) gönderiyorum. Anlık bildirim yaptığım
                  alıcıları İYS’ye bildirmem gerekiyor mu?
                </summary>
                <p>
                  Hayır. Bugünkü uygulama kapsamında bildirmeniz
                  gerekmemektedir.
                </p>
              </details>

              <details>
                <summary>
                  İki farklı işletmem var. Biri için aldığım onayı diğeri için
                  kullanabilir miyim?
                </summary>
                <p>
                  Hayır. Alıcıdan her bir Hizmet Sağlayıcı için onay almanız
                  gerekir. Ancak acentelik, özel yetkili işletme ya da bayilik
                  sözleşmesindeki taraflardan birine verilen onay; bu sözleşmeye
                  konu mal, hizmet veya markayla sınırlı olarak sözleşmenin
                  diğer tarafı için de verilmiş kabul edilir.
                </p>
              </details>

              <details>
                <summary>
                  Sahip olduğum işletme için birden fazla tescilli markam
                  bulunuyor. İzin yönetimini ayrı ayrı yapabilir miyim?
                </summary>
                <p>
                  Evet, tescil edilmiş markalarınız için izin yönetimini ayrı
                  ayrı yapabilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  Vakıf üniversitesi veya özel eğitim ve öğretim kurumu
                  niteliğine sahip Hizmet Sağlayıcıların İleti Yönetim
                  Sistemi'ne kaydolmaları gerekir mi?
                </summary>
                <p>
                  Ticari İletişim ve Ticari Elektronik İletiler Hakkında
                  Yönetmelik’in 2 nci maddesinin ikinci fıkrasının (b) bendinde;
                  <br />
                  <i
                    >"Bu Yönetmelik hükümleri; Vakıf üniversitelerinin ve diğer
                    özel eğitim ve öğretim kurumlarının
                    <u
                      >öğrencilerine ve bunların velilerine gönderdiği
                      iletilere</u
                    >
                    uygulanmaz."</i
                  >
                  hükmü yer almaktadır. <br /><br />
                  Bu tanıma uyan Hizmet Sağlayıcılar Yönetmelik’ten muaf
                  tutulmuştur. Ancak bu Hizmet Sağlayıcıların altı çizili
                  kapsamın dışında kalan bir ticari elektronik ileti göndermesi
                  durumda İYS'ye kaydolmaları gerekmektedir.
                </p>
              </details>

              <details>
                <summary>
                  Kamu kurumu niteliğindeki meslek kuruluşu, kamuya yararlı
                  dernek veya vergi muafiyeti sağlanan vakıf niteliğine sahip
                  Hizmet Sağlayıcıların İleti Yönetim Sistemi'ne kaydolmaları
                  gerekir mi?
                </summary>
                <p>
                  Ticari İletişim ve Ticari Elektronik İletiler Hakkında
                  Yönetmelik’in 2 nci maddesinin ikinci fıkrasının (c) bendinde;
                  <br />
                  <i
                    >"Bu Yönetmelik hükümleri; Kamu kurumu niteliğindeki meslek
                    kuruluşları ile kamuya yararlı dernekler ve vergi muafiyeti
                    sağlanan vakıfların,
                    <u
                      >kendilerine ait ticari işletmelerin faaliyetleriyle
                      ilgili olarak üyelerine gönderdiği iletilere</u
                    >
                    uygulanmaz."</i
                  >
                  hükmü yer almaktadır. <br /><br />
                  Bu tanıma uyan Hizmet Sağlayıcılar Yönetmelik’ten muaf
                  tutulmuştur. Ancak bu Hizmet Sağlayıcıların altı çizili
                  kapsamın dışında kalan bir ticari elektronik ileti göndermesi
                  durumda İYS'ye kaydolmaları gerekmektedir.
                </p>
              </details>

              <!-- <details>
                <summary>
                  Seviye 1 Katma Değerli test Hizmetler ile ne sunulmaktadır?
                </summary>
                <p>
                  Seviye 1 hizmeti, hizmet sağlayıcıya İYS’ye API üzerinden
                  erişim hakkı verir. Bu sayede, Temel Hizmetler’de yalnızca İYS
                  Web üzerinden yapılabilecek işlemlere web servis yoluyla da
                  erişilebilir. <br />• Farklı kriterlere göre anlık olarak
                  iletişim adresinin izin durumu sorgulanabilir. <br />• Çift
                  yönlü teknik entegrasyon (call-back) sağlanabilir. Böylelikle,
                  İYS üzerinde meydana gelen onay/ret değişikliklerinden anlık
                  olarak (veya teknik altyapının uyumuna göre belirli
                  aralıklarla) haberdar olunur.
                </p>
              </details> -->

              <!-- <details>
                <summary>
                  Seviye 2 Katma Değerli Hizmetler ile ne sunulmaktadır?
                </summary>
                <p>
                  Seviye 2, en kapsamlı İleti Yönetim Sistemi hizmetlerini ifade
                  eder. Seviye 2 hizmetleriyle birlikte: <br />• İYS kısa mesaj
                  numarası 3338, İYS kısa link veya İYS Mobil uygulaması
                  kullanılarak İYS yoluyla onay alınabilir. <br />• İYS yoluyla
                  alınacak onay/ret işlemleri için firma içi uygulamalarla
                  doğrudan teknik entegrasyon sağlanabilir. <br />• Birden fazla
                  alt kullanıcı oluşturulabilir ve bu kullanıcılar çeşitli
                  rollerde yetkilendirilebilir. <br />• Farklı zaman aralıkları
                  ve filtrelerle gelişmiş raporlar alınabilir. <br />• Kapsamlı
                  bayi/acente yönetimi uygulanabilir. <br />• İYS Web
                  (iys.org.tr) üzerinde gelişmiş kullanıcı arayüzlerine
                  erişilebilir.
                </p>
              </details> -->

              <details>
                <summary>İYS API nedir?</summary>
                <p>
                  API (Application Programming Interface), farklı yazılım
                  uygulamalarının birbirleriyle etkileşime geçmelerine olanak
                  sağlayan servis çözümüdür. <br />
                  İYS API kullanılarak, İYS uygulamaları üzerinden yapılabilen
                  işlemler karşı taraf uygulamalarıyla da gerçekleştirilebilir.
                  <br />
                  İYS API, gelişen ve değişen ihtiyaçlara göre güncellenir. İYS
                  API güncel sürümleri ilgili kanallardan müşterilerle
                  paylaşılır.
                </p>
              </details>

              <details>
                <summary>İş ortağı kimdir?</summary>
                <p>
                  İş ortağı, İYS API lisansı kullanım hakkı elde ederek kendi
                  müşterilerine İYS entegrasyon hizmeti sunan kişidir. <br />
                  CRM yazılım hizmeti, toplu SMS gönderimi, toplu e-posta
                  gönderimi, çağrı merkezi hizmeti ve benzeri hizmetler veren
                  firmalar iş ortağı olabilir. <br />
                  Firmaların iş ortağı olabilmek için şu şartları sağlamaları
                  gerekir: <br />• ISO 22301 ve TÜRKAK onaylı ISO 27001 kalite
                  belgelerine sahip olunması veya bu belgelerin on iki ay içinde
                  tamamlanacağının taahhüt edilmesi <br />• İYS İş Ortaklığı
                  Sözleşmesi’nin imzalanması
                </p>
              </details>

              <details>
                <summary>İş ortağı çalışma modeli nasıldır?</summary>
                <p>
                  İş ortaklığı modeli ve çalışma koşulları, İş Ortaklığı
                  Sözleşmesi'nde düzenlenmiştir. Bu sözleşmeyi imzalayan iş
                  ortağı firma, sözleşmeye konu olan ürün veya hizmetine İYS API
                  entegrasyonunu gerçekleştirir. Böylece, İYS'nin sunduğu Seviye
                  1 veya Seviye 2 Katma Değerli Hizmetlere, iş ortağın
                  müşterilerinin ilgili ürün veya hizmetin içinden erişimi
                  mümkün olur. <br />
                  İYS hizmetlerinin, kendi ürünü veya hizmeti içinden müşteriye
                  sunum şekli iş ortağının tasarrufundadır.<br /><br />

                  <img
                    src="@/assets/img/sss/iys_is_ortagi_modeli.png"
                    alt="İleti Yönetim Sistemi - İş Ortağı Modeli"
                  />
                </p>
              </details>

              <details>
                <summary>
                  BTK lisansına sahip bir STH firması değilim. Yine de iş ortağı
                  olabilir miyim?
                </summary>
                <p>
                  Evet. İleti Yönetim Sistemi’nin ilan etmiş olduğu teknik
                  şartlara sahip olmanız durumunda iş ortağı olabilirsiniz.
                </p>
              </details>
            </div>

            <!-- ++++++++++++++++++++++++++++++++++++ TAB 3 - > API Destek ++++++++++++++++++++++++++++++++++++ -->

            <div v-show="tab_id == 3" class="accordion" ref="sssapi">
              <details open>
                <summary>İYS API’de token yaratma limiti var mı?</summary>
                <p>
                  Token expire süresi 2 saattir. 2 saat boyunca aynı token ile
                  işlem yapabilirsiniz. Token oluşturmada herhangi bir sınır
                  bulunmamaktadır.
                </p>
              </details>

              <details>
                <summary>
                  API erişim şifremi hangi sıklıkta güncellemeliyim?
                </summary>
                <p>
                  Güvenlik politikaları gereği API erişim şifrenizi 6 ayda bir
                  güncellemeniz gerekmektedir.
                </p>
              </details>

              <details>
                <summary>
                  İYS API’de iysCode ve brandCode değerleri sandbox (test) ve
                  canlı ortam için farklılaşıyor mu?
                </summary>
                <p>
                  Evet. <b>iysCode</b> ve <b>brandCode</b> test ve canlı ortam
                  için farklıdır.
                </p>
              </details>

              <details>
                <summary>
                  İYS Web ( https://hs.iys.org.tr ) ekranlarından izinlerimi
                  nasıl kontrol edebilirim?
                </summary>
                <p>
                  İYS Web
                  <a href="https://hs.iys.org.tr " class="blue" target="_blank">
                    ( https://hs.iys.org.tr )
                  </a>
                  adresine giriş yapılarak, İzin Yönetimi sekmesi altından
                  izinler kontrol edilebilir. Mesaj İzinleri, Arama İzinleri,
                  E-Posta İzinleri ayrı ayrı olmak üzere Bireysel veya Tacir
                  tipi seçilerek listelenir.
                </p>
              </details>

              <details>
                <summary>
                  İzin yükleme esnasında “H178: Sistemdeki iznin tarihinden
                  önceki tarihli izinlerle güncelleme yapılamaz.” hata mesajı
                  alınıyor, ne anlama gelmektedir?
                </summary>
                <p>
                  Sisteme kayıtlı bir izin aynı ConsentDate tarihi ile yeniden
                  yüklendiğinde <b>H178</b> hatası alınmaktadır.
                  <b>H178</b> hata mesajının alındığı durumda ConsentDate’ in
                  sistemdeki tarihten farklı olacak şekilde güncellenerek, iznin
                  yeniden yüklenmesi gerekmektedir.
                </p>
              </details>

              <details>
                <summary>
                  İYS sistemlerine erişim için tanımlanan IP bilgilerimi
                  güncellemek için ne yapmalıyım?
                </summary>
                <p>
                  Tanımlı IP’lerinizi güncellemek için API Destek platformumuza
                  kayıtlı e-posta adresleri üzerinden
                  <b>destek@iys.org.tr</b> adresine e-posta üzerinden
                  başvurabilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  Aracı Hizmet Sağlayıcı nasıl belirleyebilirim?
                </summary>
                <p>
                  Firma yetkilileri İYS Web
                  <a href="https://hs.iys.org.tr " class="blue" target="_blank">
                    ( https://hs.iys.org.tr )
                  </a>
                  adresine e-devlet bilgileri ile giriş yaparak İşyerim/Marka
                  Yönetimi kısmından Aracı Hizmet Sağlayıcı ekleyebilmektedir.
                </p>
              </details>

              <details>
                <summary>
                  Yeni eklediğimiz izin PULL servisinde neden
                  listelenmemektedir?
                </summary>
                <p>
                  Son 1 saat içinde eklenen izinler PULL servisinde
                  listelenmemektedir.
                </p>
              </details>

              <details>
                <summary>
                  Email adreslerinin validasyonu için kullanılan güncel regex
                  adresi nedir?
                </summary>
                <p>
                  Güncel regex bilgisine
                  <a
                    href="https://regex101.com/r/yEyjL3/27"
                    class="blue"
                    target="_blank"
                    >https://regex101.com/r/yEyjL3/27
                  </a>

                  üzerinden ulaşılabilir.
                </p>
              </details>

              <details>
                <summary>
                  İzin yükleme sırasında "H194: İzin listede daha önce
                  tanımlandı. Liste içinde izinler alıcı tipi (recipientType),
                  tip (type) ve alıcı (recipient) değerleri için tekrarlanamaz."
                  hatası alınıyor. Ne anlama gelmektedir?
                </summary>
                <p>
                  Yüklenilen izin listesi içerisinde aynı adresin birden fazla
                  olması durumunda bu hata mesajı alınır. Listede içerisinde
                  birden fazla olan adresler çıkarılacak izin yüklemesi
                  yapılabilir.
                </p>
              </details>

              <details>
                <summary>İş Ortağı yetkilendirmesi nasıl yapılır?</summary>
                <p>
                  <b>İLETİ+</b> paketi tanımlanmış Hizmet Sağlayıcı, İYS Web
                  <a href="https://hs.iys.org.tr" class="blue" target="_blank"
                    >( https://hs.iys.org.tr )
                  </a>

                  üzerinden Marka Yönetimi sekmesi altındaki
                  <b>İş Ortağı Ekle</b> butonuna tıklayarak, iş ortağı
                  yetkilendirmesi yapılabilmektedir.
                </p>
              </details>

              <details>
                <summary>
                  İzin Yükleme esnasında “H467: Üç iş günü öncesinde alınan
                  izinler kaydedilemez.” hatası alıyorum, ne anlama gelmektedir?
                </summary>
                <p>
                  150.000 üzerinde iletişim adresi sayısına sahip firmalar için
                  geçmiş tarihli izinler 1 Ocak 2021, 150.000 altında iletişim
                  adresi sayısına sahip firmalar için geçmiş tarihli izinler 1
                  Haziran 2021 itibariyle yüklenememektedir. Belirtilen tarihler
                  sonrasında sadece son 3 iş gününe ait izinler
                  yüklenebilmektedir. Son 3 iş gününden daha eski izinler için
                  <b>H467</b> hatası oluşmaktadır.
                </p>
              </details>

              <details>
                <summary>
                  API kullanıcısı şifre değişikliğini nasıl yapabilirim?
                </summary>
                <p>
                  Sadece <b>İLETİ+</b> paketine sahip Hizmet Sağlayıcılar, İleti
                  Yönetim Sistemine İYS Web
                  <a href="https://hs.iys.org.tr" class="blue" target="_blank">
                    ( https://hs.iys.org.tr )
                  </a>
                  adresinden Hizmet Sağlayıcı yetkili kişisinin veya (varsa) ek
                  olarak tanımlanmış iki hesap yöneticisinin e-devlet
                  bilgileriyle giriş sağlayarak API Erişim sekmesinden yeni
                  şifre belirleyebilmektedir.
                </p>
              </details>

              <details>
                <summary>İYS API Test hesabı nasıl oluşturabilirim?</summary>
                <p>
                  İYS API Test hesabı, İYS Web
                  <a href="https://hs.iys.org.tr" class="blue" target="_blank">
                    ( https://hs.iys.org.tr )
                  </a>
                  adresinden Hizmet Sağlayıcı yetkili kişisinin veya (varsa) ek
                  olarak tanımlanmış iki hesap yöneticisinin e-devlet
                  bilgileriyle giriş sağlayarak API Test Hesabı sekmesinden
                  oluşturulabilmektedir.
                </p>
              </details>

              <details>
                <summary>
                  Tacir olarak izinleri güncellemek istediğimde izin tarihi ve
                  izin kaynağı bilgilerini girmem gerekir mi?
                </summary>
                <p>
                  <b>Tacir</b> alıcı tipindeki izinler ilk kez İYS'ye eklenirken
                  "İzin Tarihi" ve "İzin Kaynağı" değerleri zorunlu değildir.
                  Ancak İYS'ye kaydedilmiş bir izin güncellenirken bu değerler
                  zorunludur. Güncel tarih bilgisi yazarak işlemlerinizi
                  tamamlayabilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  Mesaj ve e-posta izinlerinde onay tarihi olmayan e-posta
                  adresi ve telefon numaralarını İYS’ye aktarabilir miyim?
                </summary>
                <p>
                  Tarih bilgisi olmayan izinlerin yüklenmesi Hizmet
                  Sağlayıcılarımızın sorumluluğundadır. Vatandaşların izin
                  verdiği tarih ile işlem yapılmadığı takdirde şikayetlere maruz
                  kalabilme durumu oluşabilir.
                </p>
              </details>

              <details>
                <summary>
                  Bir izinin recipientType, Type ve Source değerini
                  güncelleyebilir miyim?
                </summary>
                <p>
                  Daha önce eklenmiş bir iznin <b>recipientType</b>,
                  <b>Type</b> ve <b>Source</b>
                  değeri güncellenememektedir.
                </p>
              </details>

              <details>
                <summary>
                  İYS Sandbox ortamında “The request was aborted: Could not
                  create SSL/TLS secure channel.” uyarısı alıyorum, ne
                  yapmalıyım?
                </summary>
                <p>
                  TLS 1.2 ve TLS 1.3 ile işlem yapılmalıdır. Hatanın devam
                  etmesi durumunda .cer uzantılı public sertifikası export
                  edilip entegrasyonun bulunduğu sunucuya import edilmelidir.
                  Canlı ortamda iys.org.tr adresindeki sertifika
                  kullanılmaktadır.
                  <a
                    href="https://www.ssllabs.com/ssltest/analyze.html?d=api.iys.org.tr"
                    class="blue"
                    target="_blank"
                    >https://www.ssllabs.com/ssltest/analyze.html?d=api.iys.org.tr
                  </a>
                </p>
              </details>

              <details>
                <summary>
                  Günlük İzin Hareket Dosyasında yer alan bir izni, İzin
                  Hareketi Sorgulama (PULL) servinde görememekteyim, neden
                  olabilir?
                </summary>
                <p>
                  Pull servisinde 7 takvim günü içerisinde gerçekleşen izin
                  hareketleri İYS'ye kayıt tarihine <b>(creationDate)</b> göre
                  sıralı olarak listelenir. Günlük izin hareket dosya tarihinin
                  son 7 takvim günü içerisinde olup olmadığı yönünde kontrol
                  sağlanması gerekmektedir.
                </p>
              </details>

              <details>
                <summary>
                  İYS API test ortam erişim bilgilerine nasıl ulaşabilirim?
                </summary>
                <p>
                  Hizmet Sağlayıcılarımız, İYS Web (
                  <a href="https://hs.iys.org.tr" class="blue" target="_blank"
                    >https://hs.iys.org.tr </a
                  >) üzerinden Hesap Yönetici ya da Hesap Sahibi e-devlet
                  bilgileri ile giriş sağlayarak API Test Hesabı sekmesinden
                  Sandbox (test) ortam erişim bilgilerine ulaşabilmektedir.
                </p>
              </details>

              <details>
                <summary>
                  İYS API test ortam erişiminde IP kısıtlaması uygulanmakta
                  mıdır?
                </summary>
                <p>
                  İYS API test ortam erişimlerinde 16 Şubat 2022 tarihi
                  itibariyle IP kısıtlaması uygulanmaktadır. Subnet değeri en
                  düşük 29 olacak şekilde en fazla 5 IP adresini API Destek
                  Platformu adresine (<b>destek@iys.org.tr</b>) kayıtlı
                  kullanıcılar üzerinden e-posta göndererek iletebilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  İYS API canlı ortam’ a erişim sırasında 401 hata kodu
                  alıyorum. Hangi kontrolleri sağlamalıyım?
                </summary>
                <p>
                  İYS API canlı ortam’ a erişim sağladığınız IP adresinin İYS’
                  de kayıtlı olup olmadığı yönünde kontrol sağlanmalıdır.

                  <br />•
                  <a href="https://myip.iys.org.tr" class="blue" target="_blank"
                    >( https://myip.iys.org.tr )
                  </a>
                  adresinden IP adresinizi kontrol ediniz.

                  <br />• Hizmet Sağlayıcılar, İYS Web (
                  <a href="https://hs.iys.org.tr" class="blue" target="_blank"
                    >https://hs.iys.org.tr </a
                  >) üzerinden Hizmet Sağlayıcı yetkili kişisinin veya (varsa)
                  ek olarak tanımlanmış iki hesap yöneticisinin e-devlet
                  bilgileriyle giriş sağlayarak API Erişim sekmesinden IP
                  adresinin tanımlı olup olmadığını kontrol etmelidir. Erişim
                  sağlanan IP adresinizin tanımlı olmaması durumunda API Destek
                  Platformu adresine <b>( destek@iys.org.tr )</b> kayıtlı
                  kullanıcılar üzerinden e-posta göndererek IP adresinizi
                  ekletebilirsiniz.

                  <br />• İş ortakları, destek platformuna kayıtlı e-posta
                  adresleri üzerinden <b>( destek@iys.org.tr )</b> adresine
                  e-posta göndererek IP adreslerini teyit etmelidir.
                </p>
              </details>

              <details>
                <summary>
                  İYS numarası ve marka bilgilerine nasıl ulaşabilirim?
                </summary>
                <p>
                  İleti Yönetim Sistemine İYS Web
                  <a href="https://hs.iys.org.tr" class="blue" target="_blank"
                    >( https://hs.iys.org.tr )
                  </a>
                  adresinden Hizmet Sağlayıcı yetkili kişisinin veya (varsa) ek
                  olarak tanımlanmış iki hesap yöneticisinin e-devlet
                  bilgileriyle giriş sağlayarak Marka Yönetimi sekmesinden ya da
                  API üzerinden Marka Listeleme metodunu
                  <a
                    href="https://apidocs.iys.org.tr/#operation/getBrandsDetails"
                    class="blue"
                    target="_blank"
                    >( https://apidocs.iys.org.tr/#operation/getBrandsDetails )
                  </a>

                  çağırarak ulaşabilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  İYS API Çoklu izin ekleme servisi ile izin ekleme isteği
                  gönderdim. API’ den cevap olarak subRequestId dönmesine rağmen
                  izin eklenmemiş gözüküyor. Nasıl kontrol edebilirim?
                </summary>
                <p>
                  API’ den cevap olarak dönen subRequestId iznin yüklenmek üzere
                  İYS’ ye başarılı şekilde iletildiğini göstermekte olup, iznin
                  eklendiği anlamına gelmemektedir. Bu sebeple eklemiş olduğunuz
                  iznin durumunu tekil izin ekleme servisi
                  <a
                    href="https://apidocs.iys.org.tr/#operation/consentDetail"
                    class="blue"
                    target="_blank"
                    >( https://apidocs.iys.org.tr/#operation/consentDetail )
                  </a>

                  ya da çoklu izin sorgulama servisi

                  <a
                    href="https://apidocs.iys.org.tr/#operation/searchRequestDetails"
                    class="blue"
                    target="_blank"
                  >
                    ( https://apidocs.iys.org.tr/#operation/searchRequestDetails
                    )
                  </a>

                  ile kontrol edebilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  İYS API’ ye erişim için IP adresi yerine İYS’ nin servislerini
                  çağıracak servisin domain adresi üzerinden erişim sağlayabilir
                  miyiz?
                </summary>
                <p>
                  Hayır. İYS API’ ye sadece tanımlı IP adresleri üzerinden
                  erişim sağlanmaktadır. Domain adresi üzerinden İYS API’ ye
                  erişim sağlanamamaktadır.
                </p>
              </details>

              <details>
                <summary>
                  Kullandığımız entegrasyon modülü cloud tabanlı olduğundan IP
                  adres sayımız belirtilen 5 adet IP sınırının çok üstündedir.
                  Bu konuda nasıl ilerleyebiliriz?
                </summary>
                <p>
                  Bir IP bloğu kullanıyorsanız İYS 'ye istek göndermeden önce
                  Proxy yapısı kullanarak tek bir IP'den gelebilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  Türkiye’den hizmet alan yurtdışı numaraları için İYS’ de süreç
                  nasıl ilerletilmelidir?
                </summary>
                <p>
                  Yurtdışı numaraları için İYS süreci bulunmamaktadır. İYS' ye
                  yurtdışı numaralarının eklenip eklenmemesi Hizmet Sağlayıcının
                  insiyatifindedir.
                </p>
              </details>

              <details>
                <summary>
                  Asenkron Çoklu İzin Ekleme servisi ile bir seferde en fazla
                  kaç adet izin eklenebilmektedir?
                </summary>
                <p>
                  Asenkron Çoklu İzin Ekleme servisi ile bir seferde en fazla
                  1000 adet izin eklenebilmektedir.
                </p>
              </details>

              <details>
                <summary>
                  Bir IP adresinden saniyede kaç istek gönderilebilir?
                </summary>
                <p>Bir IP adresinden saniyede en fazla 10 istek yapılabilir.</p>
              </details>

              <details>
                <summary>
                  Hizmet Sağlayıcı olarak birden fazla iş ortağı
                  yetkilendirebilir miyim?
                </summary>
                <p>
                  Evet. Hizmet Sağlayıcılar birden fazla iş ortağı
                  yetkilendirebilmektedir.
                </p>
              </details>

              <details>
                <summary>
                  Tüm hata kodlarının listesini içeren bir dokümanınız
                  bulunmakta mıdır?
                </summary>
                <p>
                  Evet. API isteklerinde cevap olarak dönülen hataların
                  kodlarına ve mesajlarına
                  <a
                    href=" https://apidocs.iys.org.tr/#tag/HATA-KODLARI"
                    class="blue"
                    target="_blank"
                  >
                    https://apidocs.iys.org.tr/#tag/HATA-KODLARI
                  </a>

                  adresinden ulaşabilirsiniz.
                </p>
              </details>

              <details>
                <summary>
                  İYS API sürüm notlarını içeren bir dokümanınız bulunmakta
                  mıdır?
                </summary>
                <p>
                  Evet. İYS API sürüm notlarına
                  <a
                    href=" https://apidocs.iys.org.tr/#tag/SURUM-NOTLARI "
                    class="blue"
                    target="_blank"
                  >
                    https://apidocs.iys.org.tr/#tag/SURUM-NOTLARI
                  </a>

                  adresinden ulaşabilirsiniz.
                </p>
              </details>

              <details>
                <summary>İYS API servis IP adresleri nedir?</summary>
                <p>
                  İYS API canlı ortam IP adresi <b>193.34.133.120</b>, test
                  ortam IP adresi <b>193.34.133.122</b>’dir.
                </p>
              </details>

              <details>
                <summary>İYS API servislerinin timeout süresi nedir?</summary>
                <p>İYS API servislerinin timeout süresi 60 sn’dir.</p>
              </details>

              <details>
                <summary>3 iş günü kuralı nasıl hesaplanmaktadır?</summary>
                <p>
                  Cumartesi, Pazar ve resmî tatiller iş gününden
                  sayılmamaktadır.
                </p>
              </details>

              <details>
                <summary>
                  İYS API günlük izin hareket dosyası ile mutabakat özeti
                  sorgulama servisinden temin edilen izin sayıları aynı değil.
                  Neden kaynaklanabilir?
                </summary>
                <p>
                  Günlük izin hareket dosyasında hem BIREYSEL hem de TACIR
                  izinler birlikte listelenmektedir. Mutabakat Özeti Sorgulama
                  servisinde <b>BIREYSEL</b> ve <b>TACIR</b> olarak ayrı ayrı
                  gelmektedir. recipientType <b>TACIR</b> ve
                  <b>BIREYSEL</b> olarak da ilgili tarih için mutabakat özeti
                  sorgulaması yapılmalıdır.
                </p>
              </details>

              <details>
                <summary>
                  Telefon numaraları için kullanılan kütüphane ve kütüphane de
                  yer alan validasyonlara nasıl ulaşabilirim?
                </summary>
                <p>
                  Kullandığımız kütüphane:
                  <a
                    href="https://github.com/google/libphonenumber "
                    class="blue"
                    target="_blank"
                  >
                    https://github.com/google/libphonenumber
                  </a>
                  <br />
                  Kütüphanede yer alan validasyonlar:
                  <a
                    href=" https://github.com/google/libphonenumber/tree/v8.12.47/java/libphonenumber/src/com/google/i18n/phonenumbers/data"
                    class="blue"
                    target="_blank"
                  >
                    https://github.com/google/libphonenumber/tree/v8.12.47/java/libphonenumber/src/com/google/i18n/phonenumbers/data
                  </a>
                </p>
              </details>

              <details>
                <summary>
                  Esnaf/Tacir olarak gönderilen kayıtların izin işlem tarihinde
                  3 iş günü zorunluluğu bulunuyor mu?
                </summary>
                <p>
                  Yeni <b>TACIR</b> izni eklerken 3 iş günü kuralı bulunmayıp,
                  bu iznin güncellenmesi durumunda 3 iş günü kuralı devreye
                  girmektedir.
                </p>
              </details>

              <details>
                <summary>
                  Hizmet Sağlayıcı arama, mesaj izinlerine API için okuma ve
                  yazma yetkisini birden fazla iş ortağına tanımlanabiliyor mu?
                </summary>
                <p>
                  Hizmet Sağlayıcılar birden fazla iş ortağına yetki
                  tanımlayabilmektedir.
                </p>
              </details>

              <details>
                <summary>
                  İYS API Canlı ortam erişim bilgilerine nasıl ulaşabilirim?
                </summary>
                <p>
                  Hizmet Sağlayıcılarımız, İYS Web
                  <a href=" https://hs.iys.org.tr" class="blue" target="_blank">
                    ( https://hs.iys.org.tr)
                  </a>
                  üzerinden hesap yöneticisi ya da hesap sahibi e-devlet
                  bilgileri ile giriş sağlayarak canlı ortam erişim bilgilerini
                  güncelleyebilmektedir.
                </p>
              </details>

              <details>
                <summary>
                  IYS_AHS_HAT_KAPATMA izin kaynağı(source) değeri hangi durumda
                  alınmaktadır?
                </summary>
                <p>
                  Aracı hizmet sağlayıcı(operatör) tarafından hat kapatma
                  bildirimi yapılan kayıtlar için izin kaynağı(source) değeri
                  IYS_AHS_HAT_KAPATMA olarak gelmektedir.
                </p>
              </details>

              <details>
                <summary>
                  IYS_AHS_HAT_KAPATMA izin kaynağı(source) değerinde, izin
                  tarihi(consentDate) bazı kayıtlar için neden boş gelmektedir?
                </summary>
                <p>
                  Alıcı tipi TACIR olan izin İYS' ye ilk kaydedildiğinde izin
                  tarihi(consentDate) opsiyonel bir alandır. TACIR tipli
                  izinlerin güncellemesinde consentDate alanı zorunlu alandır.
                  TACIR izin İYS' ye ilk kaydedildiğinde izin tarihi iletilmezse
                  IYS_AHS_HAT_KAPATMA source değeri için izin
                  tarihi(consentDate) boş gelmektedir.
                </p>
              </details>

              <details>
                <summary>
                  IYS_AHS_HAT_KAPATMA izin kaynağı(source) değeri için
                  consentDate hangi tarih bilgisini göstermektedir?
                </summary>
                <p>
                  IYS_AHS_HAT_KAPATMA izin kaynağı(source) için izin
                  tarihi(consentDate), iznin İYS' deki son güncel izin tarihi
                  bilgisi ile gelmektedir.
                </p>
              </details>

              <details>
                <summary>
                  IYS_AHS_HAT_KAPATMA izin kaynağı(source) değeri için
                  creationDate hangi tarihi belirtmektedir?
                </summary>
                <p>
                  IYS_AHS_HAT_KAPATMA source değeri için creationDate, aracı
                  hizmet sağlayıcı tarafından hat kapatma bildirimi yapılarak
                  iznin sistemden silindiği tarihi belirtmektedir.
                </p>
              </details>
            </div>

            <div v-show="noresult && !ie" class="noresult">
              <img src="@/assets/img/ico_search.svg" alt />
              <div class="title">Sonuç bulunamadı.</div>
              <div>
                Aramış olduğunuz kelime bu bölümdeki herhangi bir soru veya
                cevap içerisinde bulunamadı.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="staticcolumn">
      <div>
        <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <a
              href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=6563&MevzuatTur=1&MevzuatTertip=5"
              class=""
              target="_blank"
              >Görüntüle</a
            >
          </div>
        </div>
        <div class="ucebir">
          <h4>
            Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelik
          </h4>
          <p>
            <!-- Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k -->
          </p>
          <div class="btn second">
            <a
              href="https://www.mevzuat.gov.tr/mevzuat?MevzuatNo=20914&MevzuatTur=7&MevzuatTertip=5"
              class=""
              target="_blank"
              >Görüntüle</a
            >
          </div>
        </div>

        <!-- <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div> -->
      </div>
    </div>

    <!-- <div class="staticcolumn">
      <div>
        <h3>Mevzuat</h3>
        <div class="ucebir">
          <h4>E-Ticaret Kanunu</h4>
          <p>6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun</p>
          <div class="btn second">
            <router-link to="/iys/kanun">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>15 Temmuz 2015 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik">Görüntüle</router-link>
          </div>
        </div>

        <div class="ucebir">
          <h4>04 Ocak 2020 Tarihli Yönetmelik</h4>
          <p>
            Ti̇cari̇ İleti̇şi̇m ve Ti̇cari̇ Elektroni̇k İleti̇ler Hakkında Yönetmeli̇kte
            Deği̇şi̇kli̇k Yapılmasına Dai̇r Yönetmeli̇k
          </p>
          <div class="btn second">
            <router-link to="/iys/yonetmelik-degisiklik">Görüntüle</router-link>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Mark from "mark.js";
import GeriBildirim from "./GeriBildirim";
import Vue from "vue";

function isIE() {
  let ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

  return is_ie;
}

const replaceAll = (string, search, replace) =>
  string.split(search).join(replace);

const tr = (txt) => {
  if (!txt) return "";
  let ret = txt
    .replace(/&#39;/gi, "'")
    .replace(/&amp;/gi, "&")
    .replace(/&AMP;/gi, "&");
  ret = replaceAll(ret, "i", "İ");
  ret = replaceAll(ret, "ı", "I");
  ret = ret.toUpperCase();
  return `${ret}`;
};

export default {
  name: "SSS",
  components: {
    GeriBildirim,
  },
  data() {
    return {
      tab_id: 1,
      searchText: "",
      indexVat: null,
      indexHs: null,
      found: false,
      hsHasGeriBildirims: false,
      original: {
        1: null,
        2: null,
      },
      cache: {},
    };
  },

  mounted() {
    // this.$refs.sssvat.children.forEach((e, i) => {
    //   e.setAttribute("id", `vat_${i}`);
    // });
    // this.$refs.ssshs.children.forEach((e, i) => {
    //   e.setAttribute("id", `hs_${i}`);
    // });
    // this.$refs.sssapi.children.forEach((e, i) => {
    //   e.setAttribute("id", `api_${i}`);
    // });
    // this.original[1] = this.$refs.sssvat.cloneNode(true);
    // this.original[2] = this.$refs.ssshs.cloneNode(true);
    // this.original[3] = this.$refs.sssapi.cloneNode(true);
    // this.mountGeriBildirims();
  },

  computed: {
    noresult() {
      if (this.ie) return false;
      return this.searchText && !this.found;
    },

    ie() {
      return isIE();
    },

    // master() {
    //   // return this.tab_id === 1 ? this.$refs.sssvat : this.$refs.ssshs;

    //   if (this.tab_id === 1) return this.$refs.sssvat;

    //   if (this.tab_id === 2) return this.$refs.ssshs;

    //   if (this.tab_id === 3) return this.$refs.sssapi;
    // },

    // preKey() {
    //   // return this.tab_id === 1 ? "vat" : "hs";
    //   if (this.tab_id === 1) return "vat";

    //   if (this.tab_id === 2) return "hs";

    //   if (this.tab_id === 3) return "api";
    // },
  },

  // watch: {
  //   tab_id(n, o) {
  //     if (!this.hsHasGeriBildirims) {
  //       this.hsHasGeriBildirims = true;
  //       this.mountGeriBildirims();
  //     }

  //     if (this.searchText === "") {
  //       this.RESET();
  //     }

  //     this.searchText = "";
  //   },

  //   searchText(n, o) {
  //     // if (n) {
  //     //   if (n.trim().length > 2) this.RESET();
  //     // }
  //     this.RESET();

  //     if (!n) return;
  //     let txt = n?.trim() || "";
  //     if (!txt) {
  //       this.$nextTick(() => {
  //         this.searchText = "";
  //       });
  //       return;
  //     }

  //     if (txt.length < 3) return;

  //     // const master = this.tab_id === 1 ? this.$refs.sssvat : this.$refs.ssshs;
  //     const master = this.master;

  //     master.children.forEach((e, i) => {
  //       e.setAttribute("open", "open");
  //     });

  //     const instance = new Mark(master);
  //     instance.mark(txt);

  //     if (this.ie) return;

  //     const self = this;

  //     master.children.forEach((e, i) => {
  //       const summary = e.children[0];
  //       const p = e.children[1];
  //       const text = summary.innerHTML + " " + p.innerHTML;
  //       if (!text.includes("<mark")) {
  //         e.classList.add("hidden");
  //       } else {
  //         self.found = true;
  //       }
  //     });
  //   },
  // },

  methods: {
    // mountGeriBildirims() {
    //   const master = this.master;
    //   const preKey = this.preKey;
    //   const GeriBildirimClass = Vue.extend(GeriBildirim);
    //   master.children.forEach((e, i) => {
    //     const summary = e.children[0];
    //     const p = e.children[1];
    //     const myKey = `${preKey}${i}`;
    //     const instance = new GeriBildirimClass({
    //       propsData: {
    //         question: summary.innerText,
    //         answer: p.innerText,
    //         myKey,
    //         initialState: this.cache[myKey],
    //       },
    //     });
    //     instance.$store = this.$store;
    //     instance.$mount();
    //     instance.$on("state-changed", this.stateChanged);
    //     e.appendChild(instance.$el);
    //   });
    // },
    // stateChanged(val) {
    //   const { myKey, initialState } = val;
    //   this.cache[myKey] = initialState;
    // },
    // RESET() {
    //   this.found = false;
    //   this.master.innerHTML = this.original[this.tab_id].innerHTML;
    //   // this.$refs.sssvat.innerHTML = this.original[1].innerHTML;
    //   // this.$refs.ssshs.innerHTML = this.original[2].innerHTML;
    //   this.$nextTick(() => {
    //     this.mountGeriBildirims();
    //   });
    // },
  },
};
</script>

<style scoped>
html,
body {
  scroll-padding-top: 100px;
}

mark {
  background: orange;
  color: black;
}

.hidden {
  display: none;
}

.highlight {
  background-color: yellow;
}

.desctext {
  display: block;
  width: 100%;
}

.accordion {
  position: relative;
  margin: 1vh 0;
  display: block;
  width: 100%;
  transition: all 0.3s;
}
.accordion * {
  transition: all 0.3s;
}
.accordion li {
  display: block;
  list-style: circle;
  margin: 1vh 5vh;
}
.accordion p {
  display: block;
  list-style: circle;
  margin: 0 0 0 0;
  padding: 0 2vh 2vh 2vh !important;
}
summary {
  font-weight: 500;
  cursor: pointer;
  padding: 2vh;
  margin: 0;
  color: #4da0df;
  outline: none;
  /* display: flex; */
  align-items: center;
}

summary:hover {
  background: #fdfbfa;
}

details {
  border-bottom: 1px solid #eeeeee;
}

details * {
  transition: all 0.3s ease-out;
}

details p {
  padding: 2vh !important;
}

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  summary {
    /* display: flex;
    flex-direction: row; */
    padding: 4vw;
    align-items: center;
    justify-content: flex-start;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}
</style>
