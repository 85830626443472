<template>
  <div class="geribildirim">
    <p v-show="INIT">
      Bu bilgi faydalı mıydı?
      <a @click="state = 'YES'">Evet</a>
      <a @click="state = 'NO'">Hayır</a>
    </p>
    <div v-show="YES">Geri bildirim için teşekkür ederiz!</div>
    <div v-show="NO">
      <!-- <div>Bu konuda üzgünüz.</div> -->

      <div class="gbbox">
        <div class="label">Nasıl geliştirebiliriz?</div>
        <textarea
          ref="aa"
          maxlength="600"
          rows="4"
          cols="50"
          v-model="message"
        />
        <div><a @click="GONDER_CLICK" :disabled="!next">Gönder</a></div>
      </div>
    </div>
    <div v-show="DONE">Mesajınız alındı. Teşekkür ederiz!</div>
  </div>
</template>

<script>
export default {
  name: "GeriBildirim",
  props: {
    question: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: true,
    },
    initialState: {
      type: String,
    },
    myKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: "",
      state: "", // INIT, YES, NO, DONE
    };
  },

  created() {
    this.state = this.initialState || "INIT";
  },

  computed: {
    next() {
      if (!this.message) return false;
      if (!this.message.trim()) return false;
      if (!this.message.length > 3) return false;
      return true;
    },
    INIT() {
      return this.state === "INIT";
    },
    YES() {
      return this.state === "YES";
    },
    NO() {
      return this.state === "NO";
    },
    DONE() {
      return this.state === "DONE";
    },
  },

  watch: {
    state: {
      immediate: true,
      handler(n, o) {
        this.$emit("state-changed", {
          myKey: this.myKey,
          initialState: this.state,
        });
        if (n === "NO") {
          this.$nextTick(() => {
            this.$refs.aa.focus();
          });
        }

        if (n === "YES") {
          // _paq.push(["trackEvent", "SSS_Gelistir", "Like", this.question]);
        }
      },
    },
  },

  methods: {
    async GONDER_CLICK() {
      const { question, answer, message } = this;

      try {
        const res = await this.$store.dispatch("app/sss_improve", {
          payload: {
            question,
            answer,
            message,
          },
        });

        this.state = "DONE";

        if (res.ok) {
          // _paq.push(["trackEvent", "SSS_Gelistir", "Feedback", this.question]);
        }
      } catch (cerror) {
        // _paq.push(["trackEvent", "SSS_Gelistir", "Feedback", "Error"]);
        this.HandleValidationError(cerror);
      }
    },
  },
};
</script>

<style scoped>
.geribildirim {
  background: #fdfbfa;
  margin: 0 2vh 40px 2vh;
  color: #798792;
  padding: 20px;
  border-radius: 10px;
}
.geribildirim .gbbox {
  display: flex;
  flex-direction: column;
  width: min-content;
  align-items: flex-end;
  margin: 10px 0;
  max-width: 100%;
}
.geribildirim .gbbox textarea {
  padding: 10px;
  margin: 0 0 10px 0;
  border: #4da0df99 1px solid;
  border-radius: 6px;
  max-width: 100%;
  line-height: 1.6 !important;
}
.geribildirim .gbbox .label {
  display: flex;
  width: 100%;
  margin-bottom: 2px;
}

.geribildirim * {
  font-size: 0.85rem !important;
}

.geribildirim p {
  padding: 0;
  margin: 0;
}
.geribildirim a {
  padding: 5px;
  margin: 0 0 0 10px;
  border: #4da0df99 1px solid;
  border-radius: 6px;
  background: #fff;
  color: #4da0df;
}
.geribildirim a:hover {
  background: #f5eeeb;
  color: #0f66a9;
}
</style>
